.wrapper {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
}

.header img {
    width: 200px;
}

.header button {
    color: white;
    background-color: rgba(50,67,109,255);
    border-radius: 5px;
    font-size: calc(10px + 1vmin);
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.reportHeader {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
}

.form-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
}

footer {
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: darkgray;
}

footer a {
    font-size: 16px;
    color: white;
}
