/* General Styles */
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
}

.header img {
  width: 200px;
}

.reportHeader {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
  color: #32436d;
}

/* Tab Navigation Styles */
.tab-header {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}

.tab-button {
  padding: 12px 24px;
  cursor: pointer;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.tab-button:hover {
  background-color: #32436d;
  color: #fff;
}

.tab-button.active {
  background-color: #32436d;
  color: #fff;
}

/* Content Styles */
.content {
  width: 100%;
}

.category-header {
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
  color: #32436d;
}

/* File Item Styles */
.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.file-name {
  width: 40%;
  font-size: 16px;
  color: #333;
}

.file-date {
  width: 30%;
  font-size: 14px;
  color: #666;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-button {
  color: #fff;
  background-color: #32436d;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #27355a;
}

/* Input and Button Styles */
.file-input-container {
  margin-top: 20px;
}

.file-input {
  display: none;
}

.open-file-button {
  color: #fff;
  background-color: #32436d;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.open-file-button:hover {
  background-color: #27355a;
}

.selected-file-name {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

/* Footer Styles */
footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f0f0f0;
  margin-top: 40px;
}

footer p {
  font-size: 14px;
  color: #333;
}

footer a {
  font-size: 14px;
  color: #32436d;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
/* Header Row Styles */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  background-color: #f5f5f5;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  width: 100%;
}

.header-patient-id {
  width: 40%;
  color: #333;
}

.header-date {
  width: 30%;
  color: #333;
}

.header-actions {
  width: 30%;
  color: #333;
  text-align: right;
}

/* Adjust widths in .file-item if necessary */
.file-name {
  width: 40%;
}

.file-date {
  width: 30%;
}

.action-buttons {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}